import React, { useState, useRef } from "react";
import "./AboutHero.css";
import AboutImg from "../../../Assets/Images/Rectangle 16.png";
import VideoAbout from "../../../Assets/Videos/new-reel-show-room-new-intro-v2.mp4";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

const AboutHero = () => {
  const { t, i18n } = useTranslation();
  const videoRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);

  const handleImageClick = () => {
    setShowVideo(true);

    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <>
      <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
        <p className={`link link-project ${i18n.language == "ar" && "ar"}`}>
          <Link to="/">{t("Home")}</Link> / {t("about")}{" "}
        </p>
      </div>
      <div
        className={`container-fluid about-hero ${
          i18n.language == "ar" && "ar"
        }`}
      >
        <div className={`${!showVideo ? "" : "display"} row`}>
          <div className={`col-md-4`} style={{ overflow: "hidden" }}>
            <div className="img" onClick={handleImageClick}>
              <svg
                fill="#fff"
                viewBox="0 -8 528 528"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M264 456q-53 0-100-27-46-27-73-73T64 256t27-100 73-73 100-27 100 27 73 73 27 100q0 53-27 100-27 46-73 73t-100 27m81-200-129-96v192z" />
              </svg>{" "}
            </div>
            <video
              src={VideoAbout}
              ref={videoRef}
              controls
              muted
              playsInline
              width="100%"
              height="100%"
            />
          </div>
          <div className="col-md-7 _size">
            <div className="text">
              <h2>{t("legacyBorn")}</h2>
              <p>{t("aboutDesc")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutHero;
